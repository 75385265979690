import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import { Mutation as TypeMutation, MutationBorrarRolArgs } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class EliminarRolGQL extends Mutation<
  TypeMutation,
  MutationBorrarRolArgs
> {
  document = gql`
    mutation borrarRol($id: ID!) {
      borrarRol(id: $id) {
        id
        nombre
        descripcion
        fechaCreacion
        fechaActualizacion
        permisos {
          id
          nombre
          descripcion
        }
      }
    }
  `;
}
