import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SignaturePadService } from "../shared/signature/signature-pad.service";
import {
  AuthGuard,
  AuthGuardChildren,
  AuthService,
  CatsService,
  EmpresasGeneradorasService,
  ItemsService,
  PermisosService,
  PersonasService,
  SettingsService,
  SharedService,
  SidebarService,
  UsuariosService,
  FileSaverService,
  ExpedientesLiquidacionService,
} from "./service.index";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    SettingsService,
    SharedService,
    SidebarService,
    AuthGuard,
    SignaturePadService,
    AuthGuardChildren,
    ItemsService,
    PermisosService,
    PersonasService,
    EmpresasGeneradorasService,
    CatsService,
    UsuariosService,
    AuthService,
    FileSaverService,
    ExpedientesLiquidacionService,
  ],
})
export class ServiceModule {}
