import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import {
  Query as TypeQuery,
  QueryProvinciasArgs,
  QueryCiudadesArgs,
} from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class CiudadesGQL extends Query<TypeQuery, QueryCiudadesArgs> {
  document = gql`
    query ciudades($filtros: Filtros) {
      ciudades(filtros: $filtros) {
        id
        nombre
      }
    }
  `;
}
