import { Injectable } from "@angular/core";
import { Mutation, gql } from "apollo-angular";
import {
  Mutation as TypeMutation,
  MutationGeneroExpedienteTipoNfUsArgs,
} from "./../Graphql";

@Injectable({
  providedIn: "root",
})
export class GeneroExpedienteTipoNFUsGQL extends Mutation<
  TypeMutation,
  MutationGeneroExpedienteTipoNfUsArgs
> {
  document = gql`
    mutation generoExpedienteTipoNFUs(
      $fechaHasta: Date!
      $idsMunicipios: [ID!]!
      $idsMarcas: [ID!]!
    ) {
      generoExpedienteTipoNFUs(
        fechaHasta: $fechaHasta
        idsMunicipios: $idsMunicipios
        idsMarcas: $idsMarcas
      ) {
        id
        nroExpediente
        cantDiasAntesDeDispParaRetirar
        empresaGeneradora {
          id
          persona {
            nombre
          }
        }
        municipio {
          id
          persona {
            nombre
          }
        }
        tipoServicio {
          id
          abreviatura
        }
      }
    }
  `;
}
