import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { EmpresasGeneradorasGQL } from "src/app/graphql/queries/empresasGeneradorasGQL";
import { EmpresaGeneradora } from "../../graphql/Graphql";

@Injectable({
  providedIn: "root",
})
export class EmpresasGeneradorasService {
  constructor(private empresasGeneradorasGQL: EmpresasGeneradorasGQL) {}

  getEmpresasGeneradores(): Observable<EmpresaGeneradora[]> {
    return this.empresasGeneradorasGQL.watch().valueChanges.pipe(
      map(({ data }) => {
        return data?.empresasGeneradoras;
      })
    );
  }
}
