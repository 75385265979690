import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import { Query as TypeQuery, QueryEmpresaArgs } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class EmpresaGQL extends Query<TypeQuery, QueryEmpresaArgs> {
  document = gql`
    query empresa($tipoDocumento: ID!, $nroDocumento: String!) {
      empresa(tipoDocumento: $tipoDocumento, nroDocumento: $nroDocumento) {
        id
        persona {
          id
          nombre
          nroDocumento
          email
          telefono
          domicilio {
            id
            direccion
            numero
            lat
            lng
            ciudad {
              id
              nombre
              provincia {
                id
                nombre
              }
            }
          }
        }
      }
    }
  `;
}
