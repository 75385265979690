import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import { Query as TypeQuery } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class MisPermisosGQL extends Query<TypeQuery> {
  document = gql`
    query misPermisos {
      misPermisos {
        id
        nombre
        descripcion
        fechaCreacion
        fechaActualizacion
      }
    }
  `;
}
