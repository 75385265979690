import { Injectable } from "@angular/core";
import { Query, gql } from "apollo-angular";
import { Query as TypeQuery } from "../Graphql";

@Injectable({
  providedIn: "root",
})
export class CatsGQL extends Query<TypeQuery> {
  document = gql`
    query cats {
      cats {
        id
        horariosAtencion
        persona {
          id
          nombre
          nroDocumento
          email
          telefono
          domicilio {
            id
            direccion
            numero
            lat
            lng
            ciudad {
              id
              nombre
              provincia {
                id
                nombre
              }
            }
          }
        }
        municipio {
          id
          ordenanza
          ordenanzaUrl
          emailNotificacion
          cantDiasAntesDeDispParaRetirar
          diasVencimientoLiquidacion
          persona {
            id
            nombre
            nroDocumento
            email
            telefono
            logo
            domicilio {
              id
              direccion
              numero
              piso
              lat
              lng
            }
          }
          tasasDeTipoNeumatico {
            id
            tasaUnitaria
            tipoNeumatico {
              id
              nombre
            }
          }
          medioDePago
          firmante
          tipoLocalidad {
            id
            nombre
          }
          mostrarFirma
          firmaEncargado
        }
      }
    }
  `;
}
